<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-card">
      <vs-table
        ref="table"
        v-model="selected"
        :max-items="itemsPerPage"
        :data="notifications"
        @selected="checkNotif"
      >
        <template slot="thead">
          <vs-th>الإشعار</vs-th>
          <vs-th>النوع</vs-th>
          <vs-th>حالة الإشعار</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="w-3/5" >
                <feather-icon
                  icon="BellIcon"
                  svgClasses="h-6 w-6"
                  class="mr-3 text-primary"
                />
                <span class="text-2xl text-primary">{{ tr.title }}</span>
                <h6 class="notif-body">{{ tr.body }}</h6>
              </vs-td>
              <vs-td>
                <vs-chip
                  transparent
                  :color="'info'"
                  class="product-order-status text-base"
                >
                  {{ checkType(tr.type) }}</vs-chip
                >
              </vs-td>
              <vs-td>
                <vs-chip
                  class="product-order-status"
                  :color="tr.is_read ? 'success' : 'warning'"
                >
                    <feather-icon :icon="tr.is_read?'CheckIcon':'XIcon'" svgClasses="h-4 w-4" class="mr-2" />
                  {{ tr.is_read ? "مقروءة" : "غير مقروءة" }}
                </vs-chip>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
    <vs-pagination
      class="mt-4"
      :total="lastPage"
      v-model="current_Page"
      @change="getData(current_Page)"
    ></vs-pagination>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      selected: [],
      notifications: [],
      current_Page: 2,
      itemsPerPage: "",
      totalItems: "",
      lastPage: 2,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.notifications.length;
    },
  },
  methods: {
    ...mapActions("notifications", ["fetchNotifications", "readNotification"]),
    getData() {
      this.$vs.loading();
      this.fetchNotifications()
        .then((response) => {
          this.notifications = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.errorDialog(error);
        });
    },
    checkType(type)
    {
      switch(type)
      {
        case 'order': return 'طلبية';
        case 'transaction': return 'معاملة مالية';
      }

    },
    checkNotif(notif) {
      switch(notif.type)
      {
        case "order": {
          this.$router.push({ name: 'edit-order', params: { id: notif.data_id } })
        } break;
        case "transaction": {
           this.$router.push({ name: 'transactions'});
        }
      }
      notif.is_read?'':this.readNotification(notif.id);
    },

    mounted() {
      this.isMounted = true;
    },
    created() {
      this.getData();
    },
  },
};
</script>
<style scoped>
.notif-body {
  line-height: 1.5em;
  font-size: 1em;
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
